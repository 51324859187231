import PinDropIcon from '@mui/icons-material/PinDrop'
import { Box, Grid, List, Tab, Tabs as MuiTabs, ListItem, styled } from '@mui/material'
import { tabSx } from 'containers/Billing'
import LoginLeft from 'assets/images/login-left.png'
import { AuthLayout, MButton, Text } from 'components'
import { LOGIN_ITEMS } from 'config'
import { useState } from 'react'

import { Item, LoginForm, PayForm } from './component'

export const SignIn: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0)
  const handleTabChange = (_e: any, newActiveTab: number): void => {
    setActiveTab(newActiveTab)
  }
  const handleFindLocation = (): void => {
    window.open(
      'https://www.checkfreepay.com/en/payment-locator.html',
      '_blank'
    )
  }

  return (
    <AuthLayout
      title="MyAlexRenew is Here!"
      desc="MyAlexRenew officially launched Nov. 3, 2023. If this is your first-time logging into the new system, you will need to register your account using your AlexRenew account number, the email address associated with your account, and the zip code of your service address. The new customer portal will allow you to view your bill, make a payment, track your wastewater usage, and set your communications preferences online."
      isAuthPage={true}
    >
      <AuthWrapper isAuthPage={true}>
        <Grid container>
          <Grid item sm={0} md={6}>
            <Box
              sx={{
                backgroundSize: 'cover',
                backgroundImage: `url("${LoginLeft}")`,
                height: '100%'
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Tabs variant='fullWidth' value={activeTab} onChange={handleTabChange} TabIndicatorProps={{ sx: { background: '#0092BD', height: 5 } }}>
              <Tab key={0} label="Login or Register" sx={tabSx} />
              <Tab key={1} label="Pay your Bill" sx={tabSx} />
            </Tabs>
            <AuthMain>
              {activeTab === 0 && <LoginForm />}
              {activeTab === 1 && <PayForm handleTabChange={handleTabChange} />}
            </AuthMain>
          </Grid>
        </Grid>
      </AuthWrapper>
      <Box>
        <Text
          color="#E3E3E3"
          align="center"
          fontSize="24px"
          margin="50px 0 40px"
        >
          How can we help you?
        </Text>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing="20px"
          rowSpacing={{ xs: 2, md: 4 }}
        >
          {LOGIN_ITEMS.map((item) => {
            return (
              <Item
                key={item.title}
                Icon={item.icon}
                title={item.title}
                route={item.link}
              />
            )
          })}
        </Grid>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        bgcolor='#336D91'
        alignItems="center"
        borderRadius={2}
        mt={10}
        px={3}
        py={5}
      >
        <Box display="flex" alignItems="center" my={2} gap={2}>
          <PinDropIcon sx={{ color: '#67D2DF' }} />
          <Text color="#fff" fontSize="18px">
            Other Payment Options
          </Text>
        </Box>
        <Box>
          <List
            sx={{
              listStyleType: 'disc',
              color: '#fff',
              pl: 5
            }}
          >
            <ListItem sx={{ display: 'list-item' }} >
              <Text color="#fff" fontSize="16px">
                Please log in to your account to set up Autopay.
              </Text>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Text color="#fff" fontSize="16px">
                Mail check or money order to AlexRenew at PO BOX 26428,
                ALEXANDRIA VA 22313-6428.
              </Text>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Text color="#fff" fontSize="16px">
                Walk-in: AlexRenew customers have the ability to walk in to
                several locations to pay their bill. Customers can search the
                locations by zip code using the link below. Please be advised
                that most retail stores charge a fee to process a payment.
              </Text>
            </ListItem>
          </List>
        </Box>
        <Box m="24px 0 0">
          <MButton
            variant="outlined"
            rounded="true"
            size="large"
            texttransform="none"
            sx={{
              color: '#fff',
              borderColor: '#A4E4EC',
              '&:hover': {
                borderColor: '#A4E4EC'
              }
            }}
            onClick={handleFindLocation}
          >
            Find Walk-In Locations
          </MButton>
        </Box>
      </Box>
    </AuthLayout >
  )
}

export const AuthWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isAuthPage'
})<{ isAuthPage?: boolean }>(({ isAuthPage }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: (isAuthPage ?? false) ? '40px 0' : '0'
}))

export const AuthMain = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isRounded'
})<{ isRounded?: boolean }>(({ isRounded }) => ({
  backgroundColor: '#fff',
  padding: '40px',
  borderRadius: (isRounded ?? false) ? '4px' : '0',
  h4: {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '32px',
    lineHeight: '38px',
    textAlign: 'center',
    color: '#000000',
    marginBottom: '32px',
    marginTop: 0
  }
}))

const Tabs = styled(MuiTabs)({
  '& .MuiTab-root': {
    background: '#ffffff',
    borderBottom: 'solid 1px #E7EEF2'
  },
  '& .MuiTab-root.Mui-selected': {
    backgroundColor: '#ffffff',
    color: '#010B0E'
  }
})
